<template>
  <div class="modal is_small is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title"></p>
        <button class="delete" aria-label="close" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <div class="columns is-multiline">
          <div class="column is-12 has-text-centered">
            <figure class="image is-3by5">
              <iframe class="has-ratio" width="640" height="360" :src="`${URLFILES}${viewDocument}`" frameborder="0" allowfullscreen></iframe>
            </figure>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
const URL_BUCKET = process.env.VUE_APP_URL_FILES
export default {
  name: 'ModalResultsSent',
  data () {
    return {
      URLFILES: URL_BUCKET
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    viewDocument: {
      type: String,
      default: ''
    },
    close: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: 0.72px;
    text-align: center;
    color: #5d6990;
    margin-bottom: 3rem;
  }
  .image_check {
    max-width: 80px;
    margin-bottom: 3rem;
  }
</style>
